<template>
  <div class="main-wrapper" id="header">
    <div class="title">&nbsp;&nbsp;&nbsp;<b>스마트그늘막</b><hr/></div>
      <div class="main1">
        <div class="mainimg1">
        <img src="@/assets/스마트그늘막 (1).jpg" alt="특허1" width="400px" height="500px">
        </div>
        <div class="mainimg1">
        <img src="@/assets/스마트그늘막 (2).jpg" alt="특허1" width="400px" height="500px">
        </div>
      </div>
    <div class="title">&nbsp;&nbsp;&nbsp;<b>파라솔</b><hr/></div>
      <div class="main2">
        <div class="mainimg1">
        <img src="@/assets/파라솔 (1).jpg" alt="특허1" width="400px" height="500px">
        </div>
        <div class="mainimg1">
        <img src="@/assets/파라솔 (2).jpg" alt="특허1" width="400px" height="500px">
        </div>
            <div class="title">&nbsp;&nbsp;&nbsp;<b>사각파라솔</b><hr/></div>
      <div class="main2">
        <div class="mainimg1">
        <img src="@/assets/사각파라솔 (1).jpg" alt="특허1" width="400px" height="500px">
        </div>
        <div class="mainimg1">
        <img src="@/assets/사각파라솔 (2).jpg" alt="특허1" width="400px" height="500px">
        </div>
      </div>
      </div>
  </div>
</template>

<script>
export default {
  name: "Main",
  data() {
    return {
    }
  },
  setup() {

    return {};
  },
  methods(){
  },
};
</script>

<style>
    .mainimg1{
      display: inline-block;
      padding:20px;
    }
</style>
