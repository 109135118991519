<template>
<div class="sb">
    <img class="sb1" src='@/assets/1.jpg'>
   <img class="sb1" src='@/assets/2.jpg'>
    <router-link to="/patent" class="footer_link">  <img class="sb1" src='@/assets/3.jpg'></router-link><br>
    <a href="https://smartstore.naver.com/18002467" class="footer_link" target="_blank">  <img class="sb1" src="@/assets/4.jpg"> </a><br>

</div>
</template>

<script>
export default {};
</script>
<style scoped>
    .sb {
        width: 176px;
        padding-top: 20px;
        display: inline-block;
        vertical-align: top;
    }
  
</style>