<template>
    <div class="qnaWriteContainer">
        <div class="title">&nbsp;&nbsp;&nbsp;
            <b>
                <template v-if="!isModify">
                    글쓰기
                </template>
                <template v-else>
                    글수정
                </template>
            </b>
            <hr/>
        </div>
        <template v-if="isPass">
            <div class="qnaWriteTable">
                <ValidationObserver>
                    <table>
                        <tr>
                            <td class="left" width="100px">제목</td>
                            <td>
                                <ValidationProvider ref="boardTitle" rules="max:50">
                                <input name="제목" type="text" v-model.trim="boardData.boardTitle" class="title-input">
                                </ValidationProvider>
                            </td>
                        </tr>
                        <tr>
                            <td class="left">작성자</td>
                            <td>
                                <ValidationProvider ref="boardAuthor" rules="max:10">
                                <input name="작성자" type="text" v-model.trim="boardData.boardAuthor">
                                </ValidationProvider>
                            </td>
                        </tr>
                        <tr>
                            <td class="left">비밀번호</td>
                            <td>
                                <ValidationProvider ref="boardPwd" rules="max:15">
                                <input name="비밀번호" type="text" v-model.trim="boardData.boardPwd">
                                </ValidationProvider>
                            </td>
                        </tr>
                        <tr>
                            <td class="left">전화번호</td>
                            <td>
                                <ValidationProvider ref="boardPhone" rules="max:15">
                                <input name="전화번호" type="text" v-model.trim="boardData.boardPhone">
                                </ValidationProvider>
                            </td>
                        </tr>
                        <tr>
                            <td class="left">비밀글</td>
                            <td>
                                <input type="checkbox" v-model="boardData.boardSecret">
                            </td>
                        </tr>
                        <tr>
                            <td class="left">본문</td>
                            <td>
                                <ValidationProvider ref="boardContent" rules="max:1000">
                                <textarea name="내용" rows="20" cols="100" v-model.trim="boardData.boardContent"></textarea>
                                </ValidationProvider>
                            </td>
                        </tr>
                    </table>
                </ValidationObserver>
                <div class="footer">
                    <button v-if="!$route.params.isModify" @click="clickBeforeButton()">이전</button>
                    <button v-if="!$route.params.isModify" @click="clickSave()">저장</button>
                    <button v-if="$route.params.isModify" @click="clickBeforeButton()">취소</button>
                    <button v-if="$route.params.isModify" @click="clickUpdateSave()">수정완료</button>
                </div>
            </div>
        </template>
        <template v-else>
            <QNASecret 
                :boardId="board.id"
                :isModify="isModify"
                v-on:goQNADetail="goQNADetail"
                v-on:goQNAUpdateForm="goQNAUpdateForm"
            />
        </template>
    </div>
</template>

<script>
import QNASecret from "@/components/qna/QNASecret.vue";
export default {
    name: "QNAWrite",
    components: {
        QNASecret,
    },
    props: {
        board: {
            type: Object,
        },
        isModify: {
            type: Boolean,
            default: false,
        }
    },
    created() {
        if (this.$route.params.isModify) {
            this.setFormData();
        }
    },
    data() {
        return  {
            boardData: {
                boardId: '',
                boardTitle: '',
                boardAuthor: '',
                boardPhone: '',
                boardPwd: '',
                boardContent: '',
                boardSecret: '',
            },
            isPass: true,
        }
    },
    methods: {
        setFormData() {
            this.isPass = false;
            this.boardData.boardId = this.board.id;
            this.boardData.boardTitle = this.board.title;
            this.boardData.boardAuthor = this.board.author;
            this.boardData.boardPwd = this.board.pwd;
            this.boardData.boardPhone = this.board.phone;
            this.boardData.boardSecret = this.board.secret;
            this.boardData.boardContent = this.board.content;
        },
        async clickSave() {
            if (this.boardData.boardTitle == '' || 
                this.boardData.boardAuthor == '' || 
                this.boardData.boardPhone == '' ||
                this.boardData.boardPwd == '' ||
                this.boardData.boardContent == '') {
                alert('빈 항목을 채워주세요');
                return false;
            }
            const refBoardTitle = await this.$refs.boardTitle.validate();
            const refBoardAuthor = await this.$refs.boardAuthor.validate();
            const refBoardPwd = await this.$refs.boardPwd.validate();
            const refBoardPhone = await this.$refs.boardPhone.validate();
            const refBoardContent = await this.$refs.boardContent.validate();
            if (!refBoardTitle.valid) {
                alert(refBoardTitle.errors[0]);
                return false;
            } else if (!refBoardAuthor.valid) {
                alert(refBoardAuthor.errors[0]);
                return false;
            } else if (!refBoardPwd.valid) {
                alert(refBoardPwd.errors[0]);
                return false;
            } else if (!refBoardPhone.valid) {
                alert(refBoardPhone.errors[0]);
                return false;
            } else if (!refBoardContent.valid) {
                alert(refBoardContent.errors[0]);
                return false;
            }

            let boardData = this.boardData;
            this.$store.dispatch('requestInsertBoard', boardData)
            .then(() => {
                alert("글작성 완료");
                this.$router.push({ path: '/qna', query: {page: 1} });
            })
            .catch((error) => {
                console.log(error);
            })
        },        
        clickBeforeButton() {
            if (this.isModify) {
                this.$router.push({path: '/qna/detail/'+this.board.id});
            } else {
                this.$router.push({path: '/qna', query: {page: 1} });
            }
        },
        async clickUpdateSave() {
            if (this.boardData.boardTitle == '' || 
                this.boardData.boardAuthor == '' || 
                this.boardData.boardPhone == '' ||
                this.boardData.boardPwd == '' ||
                this.boardData.boardContent == '') {
                alert('빈 항목을 채워주세요');
                return false;
            }
            const refBoardTitle = await this.$refs.boardTitle.validate();
            const refBoardAuthor = await this.$refs.boardAuthor.validate();
            const refBoardPwd = await this.$refs.boardPwd.validate();
            const refBoardPhone = await this.$refs.boardPhone.validate();
            const refBoardContent = await this.$refs.boardContent.validate();
            if (!refBoardTitle.valid) {
                alert(refBoardTitle.errors[0]);
                return false;
            } else if (!refBoardAuthor.valid) {
                alert(refBoardAuthor.errors[0]);
                return false;
            } else if (!refBoardPwd.valid) {
                alert(refBoardPwd.errors[0]);
                return false;
            } else if (!refBoardPhone.valid) {
                alert(refBoardPhone.errors[0]);
                return false;
            } else if (!refBoardContent.valid) {
                alert(refBoardContent.errors[0]);
                return false;
            }

            let boardData = this.boardData;
            
            this.$store.dispatch('requestUpdateBoard', boardData)
            .then(() => {
                alert("글수정 완료");
                this.$router.push({ path: '/qna/detail/'+ boardData.boardId });
            })
            .catch((error) => {
                console.log(error)
            })
        },
        goQNAUpdateForm() {
            this.isPass = true;
        },
        goQNADetail() {
            this.$router.push({ path: '/qna/detail/'+ this.boardData.boardId });
        },
    }
}
</script>

<style scoped>
.qnaWriteTable {
    margin: 20px;
}

input {
    width: 150px;
    height: 25px;
    margin: 5px;
}

input[type="checkbox"] {
    width: 25px;
}

textarea{
    margin: 5px;
}

.title-input {
    width: 700px;
}

.footer {
    text-align: right;
}
.left{
      font-size: 12px;
  width: 150px;
  padding: 10px;
  font-weight: bold;
  vertical-align: top;
  background: #efefef;
}


</style>