<template>
<div class="footer">
    <div class="menu">
        <router-link to="/About" class="footer_link">회사소개</router-link>
        <router-link to="/Manual" class="footer_link">이용약관</router-link>
        <router-link to="/Privacy" class="footer_link">개인정보취급방침</router-link>
    </div>
    <div class="copyright">
        경기도 양주시 백석읍 중앙로 137번길 104 (주)경기상사<br>
        대표이사 : 이정현 사업자등록번호 : 561-86-00358 사업자정보확인통신판매업신고번호 : 제 2023-경기양주-1807 호<br>
        대표번호 : 1800-2467 팩스번호 : 031-541-1540 메일 : newslwk@naver.com<br>
        Copyright &copy; (주)경기상사 all rights reserved </div>
    </div>
</template>

<script>
export default {};
</script>

<style scoped>
/* footer */
.footer {
    width: 100%;
    height: 200px;
    background-color: #203583;
    
}
.menu{
    padding: 20px;
    text-align: center;
}

.footer_link {
    color: #fff;
    position: relative;
    text-decoration: none;
    font-size: 15px;
    margin:100px;
}

/* 연습 */

.copyright {
    color: white;
    font-size: 15px;
    width:1100px;
    margin : 30px auto;
}


</style>
