<template>
  <div class="about">
    <AboutCompany />
  </div>
</template>

<script>
import AboutCompany from '../components/about/AboutCompany.vue';
export default {
  components: { AboutCompany },
};
</script>
<style scoped>
    .about{
        display: inline-block;
    }
  
</style>