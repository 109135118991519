<template>
    <div class="header">
        <div class="logo-banner-box">
            <MainHeaderLogin/>
            <MainHeaderLogo/>
            <MainHeaderBanner/>
        </div>
        <div class="nav-bar-box">
            <MainHeaderNavBar/> 
        </div>
    </div>
</template>

<script>
import MainHeaderLogin from "./header/MainHeaderLogin.vue";
import MainHeaderLogo from "./header/MainHeaderLogo.vue";
import MainHeaderBanner from "./header/MainHeaderBanner.vue";
import MainHeaderNavBar from "./header/MainHeaderNavBar.vue";

export default {
    name: 'MainHeader',
    components: {
        MainHeaderLogin,
        MainHeaderLogo,
        MainHeaderBanner,
        MainHeaderNavBar,
    },
    data() {
        return {

        }
    }
}
</script>

<style>

.header {
    height: 249px;
}

.header-component {
    display: flex;

}

.header .login {
    display: block;
}

.logo-banner-box {
    width: 1080px;
    height: 186px;
    margin: 0 auto;
    padding-right: 20px;
}

.header .nav-bar {
    width: 100%;
    height: 50px;
    background-color: #203583;
}


</style>

