import { instance, instanceWithAuth} from "@/axios/axios";

export default {
    login({commit}, payload) {
        commit('login', payload);
    },

    logout({commit}) {
        commit('logout');
    },

    requestLogin(context, payload) {
        const URL = '/api/member/login';
        return instance.post(URL, payload);
    },

    requestJoin(context, payload) {
        const URL = '/api/member/join';
        return instanceWithAuth.post(URL, payload);
    },

    setLoginToken({commit}, payload) {
        commit('setLoginToken', payload);
    },

    requestGetMemberId() {
        const URL = '/api/member';

        return instanceWithAuth.get(URL);
    },

    setMemberId({commit}, payload) {
        commit('setMemberId', payload);
    },

    requestGetBoard(context, payload) {
        const URL = '/api/board?page='+(payload.page-1);
        return instance.get(URL);
    },

    requestGetBoardDetail(context, payload) {
        const URL = '/api/board/'+ payload.boardId;
        return instance.get(URL);
    },

    requestInsertBoard(context, payload) {
        const URL = '/api/board';
        return instance.post(URL, payload);
    },

    requestUpdateBoard(context, payload) {
        const URL = '/api/board';
        return instance.put(URL, payload);
    },

    requestDeleteBoard(context, payload) {
        const URL = '/api/board/'+payload.boardId;
        return instance.delete(URL);
    },

    requestInsertProduct(context, payload) {
        const URL = '/api/product';
        return instanceWithAuth.post(URL, payload);
    },


    requestGetProduct(context, payload) {
        const URL = '/api/product?page='+(payload.page-1);
        return instance.get(URL);
    },

    requestGetProductDetail(context, payload) {
        const URL = '/api/product/' + payload.productId;
        return instance.get(URL);
    },

    requestCheckPassword(context, payload) {
        const URL = '/api/board/checkpwd';
        return instanceWithAuth.post(URL, payload);
    },

    requestDeleteProduct(context, payload) {
        const URL = '/api/product/'+payload.productId;
        return instanceWithAuth.delete(URL);
    },
    
    requestUpdateProduct(context, payload) {
        const URL = '/api/product';
        return instanceWithAuth.put(URL, payload);
    },
}