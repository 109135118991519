import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { ValidationProvider, extend, ValidationObserver } from "vee-validate" // vee-validate 로 부터 사용할 기능 import
import * as rules from "vee-validate/dist/rules"

for (let rule in rules) {
  // add the rule
  extend(rule, rules[rule])
}

extend('memberId', {
  validate: value => {
    let regex = /^[a-z\d]{4,15}$/.test(value);
    let regexSpace = /\s/g.test(value);
    if (regexSpace) {
      return '공백은 사용 불가합니다.';
    } else if (!regex) {
      return '아이디는 영문 소문자, 숫자조합으로 4~15자리 입력해주세요.';
    } else {
      return true;
    }
  }
});

extend('memberPwd', {
  validate: value => {
    let regex = /^[a-zA-Z\d~!@#$%^&*,.;']{6,15}$/.test(value);
    let regexSpace = /\s/g.test(value);
    if (regexSpace) {
      return "공백은 사용 불가합니다.";
    } else if (!regex) {
      return "비밀번호는 영문, 숫자, 특수문자(~!@#$%^&*,.;') 6~15자리 입력해주세요.";
    } else {
      return true;
    }
  }
});

extend("min", {
  validate(value, { min }) {
    if (value.length >= min) {
      return true
    }
    return "{_field_}는 {min} 글자 이상이어야 합니다."
  },
  params: ["min"],
})

extend("max", {
  validate(value, { max }) {
    if (value.length <= max) {
      return true
    }
    return "{_field_}는 {max} 글자 이하여야 합니다."
  },
  params: ["max"],
})


Vue.component("ValidationProvider", ValidationProvider)
Vue.component("ValidationObserver", ValidationObserver)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
