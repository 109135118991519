<template>
    <div class="product-detail-wrap">
        <div class="title">&nbsp;&nbsp;&nbsp;<b>상품정보</b> <hr/></div>
        <template v-if="isLoading">
            로딩중...
        </template>
        <template v-else>
            <template v-if="isPass">
                <div class="buttonBox">
                    <button @click="clickUpdate()">수정</button>
                    <button @click="clickDelete()">삭제</button>
                </div>
            </template>
            <div class="product-detail-content">
                <div class="summary">
                    <div id="product_image">
                        <img :src="product.productImage" alt="썸네일" width="300px" height="300px"/>
                    </div>
                    <div id="product_info">
                        <table>
                            <tr>
                                <td style="font-size:20px">상품명</td>
                                <td></td>
                                <td class="left_column" style="font-size:20px" ><strong>{{ product.productName }}</strong></td>
                                <!-- <td> <strong>{{ product.productDescription }}</strong> </td> -->
                            </tr>
                            <tr style="height: 70px">
                                <td>상품 사이즈</td>
                                <td></td>
                                <td> {{ product.productDescription }}</td>
                            </tr>
                            <tr style="height: 50px">
                                <td>원산지</td>
                                <td></td>
                                <td>국내</td>
                            </tr>
                            <tr>
                                <td>판매가</td>
                                <td></td>
                                <td>전화문의</td>
                            </tr>
                            
                        </table>
                    </div>
                </div>
                <div class="content_tytle"><strong>상품 상세 정보</strong></div>
                <div class="content_image_wrap">
                    <img :src="product.productDetailImage" alt="내용사진" width="100%" class="content_image"/>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState } from "vuex";
export default {
    name: "ProductDetail",
    props: {
        product_id: {
            type: String,
            default: "",
        },
    },
    created() {
        this.setProductData(this.product_id);
    },
    data() {
        return {
            product: {
                productId: "",
                productName: "",
                productDescription: "",
                productImage:'',
                productDetailImage:'',
            },
            isLoading: true,
        }
    }, 
    methods: {
        setProductData(product_id) {
            this.$store.dispatch('requestGetProductDetail', { productId: product_id })
            .then((response) => {
                this.isLoading=false;
                let data = response.data;
                this.product.productId = data.productId;
                this.product.productName = data.productName;
                this.product.productDescription = data.productDescription;
                this.product.productImage = data.productImage;
                this.product.productDetailImage = data.productDetailImage
              
            })
            // .catch((error) => {
            //     console.log(error);
            //     alert("오류로 인해 게시글 데이터를 불러오지 못했습니다. 목록으로 돌아갑니다.");
            //     this.$router.push({ path: '/product' })
            // })
        },
        clickDelete() {
            let isDelete = confirm("상품을 삭제하시겠습니까?");
            if (isDelete == false) return;

            this.$store.dispatch('requestDeleteProduct', { productId: this.product.productId })
            .then(() => {
                alert("상품 삭제 성공. 목록으로 돌아갑니다.");
                this.$router.push({ path: '/product', query: {page: 1}});
            })
        },
        clickUpdate() {
            this.$router.push({
                name: 'ProductWrite',
                params: {product: this.product, isModify: true},
            })
        }
    },
    computed: {
        ...mapState([
            'isLogin',
        ]),
        isPass () {
            return this.isLogin;
        }
    }
}
</script>

<style scoped>
.buttonBox {
    text-align: right;
}

.product-detail-content {
    margin: 30px 20px;
}

.summary {

}

#product_image {
    display: inline-block;
}

#product_info {
    display: inline-block;
    vertical-align: top;
    padding: 20px;
}

.left_column {
    width: 200px;
}

.content_tytle {
    margin: 50px;
    text-align: center;
    font-size: 20px; 
}

.content_image {
    display: block;
    margin: 50px auto;
}
td{
    padding:0 20px;
    
}
</style>