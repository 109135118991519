<template>
  <div>
    <div class="title">&nbsp;&nbsp;&nbsp;<b>회원가입</b><hr/></div>
    <ValidationObserver>
      <div class="joinFormBox">
        <form class="joinForm">
          <ValidationProvider ref="refMemberId" rules="memberId">
          아이디: <input name="ID" type="text" v-model="memberId">
          </ValidationProvider>
          <ValidationProvider ref="refMemberPwd" rules="memberPwd">
          비밀번호: <input name="비밀번호" type="password" v-model="memberPwd">
          </ValidationProvider>
          <button type="button" @click="clickJoin()">관리자 회원가입</button>
        </form>
      </div>
    </ValidationObserver>
  </div>
</template>

<script>
export default {
  data() {
    return {
      memberId :'',
      memberPwd: '',
    }
  },

  methods: {
    async clickJoin() {
      // 유효성 검사
      let memberId = this.memberId;
      let memberPwd = this.memberPwd;
      if (memberId == '' || memberPwd == '') {
        alert('빈 항목을 채워주세요');
        return ;
      }
      const refMemberId = await this.$refs.refMemberId.validate();
      const refMemberPwd = await this.$refs.refMemberPwd.validate();
      if (!refMemberId.valid) {
          alert(refMemberId.errors[0]);
          return false;
      }
      if (!refMemberPwd.valid) {
          alert(refMemberPwd.errors[0]);
          return false;
      }
      this.$store.dispatch('requestJoin', {memberId: memberId, memberPwd: memberPwd})
      .then(() => {
        alert("회원가입 성공. 메인페이지로 이동합니다.");
        this.$router.push({ path: '/'})
      })
      .catch((error) => {
        console.log(error);
        alert("회원가입 실패. 중복된 아이디이거나 올바르지 않은 입력입니다.");
      })
    },
  },
}
</script>

<style scoped>
.map1{
  margin: 50px 50px;
}

.joinFormBox {
  margin-top: 100px;
}

.joinForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

input {
  margin-bottom: 10px;
}
</style>
