<template>
    <div class="qna-detail-wrap">
        <div class="title">&nbsp;&nbsp;&nbsp;<b>문의사항</b><hr/></div>
        <template v-if="isLoading">
            <!-- <q-spinner-cube color="primary" size="5em"/> -->
            로딩중 ...
        </template>
        <template v-else>
            <template v-if="isPass">
                <div class="qna-detail-content">
                    <table>
                        <tr class="main">
                            <td class="left"> 제목 </td>
                            <td class="right" colspan="3">{{ board.title }} </td>
                        </tr>
                        <tr class="main">
                            <template v-if="isLogin">
                                <td class="left">글쓴이</td>
                                <td class="right">{{ board.author }}</td>
                                <td class="left">연락처</td>
                                <td class="right">{{ board.phone }}</td>
                            </template>
                            <template v-else>
                                <td class="left">글쓴이</td>
                                <td class="right" colspan="3">{{ board.author }}</td>
                            </template>
                        </tr>
                        <tr class="main">
                            <td class="left">날짜</td>
                            <td class="right">{{ board.date }}</td>
                            <td class="left">조회수</td>
                            <td class="right">{{ board.hit }}</td>
                        </tr>
                        <tr class="main">
                            <td class="content" colspan="4" v-html="content">
                            </td>
                        </tr>
                    </table>
                    <div class="qna-detail-footer">
                        <button @click="clickUpdate()">수정</button>
                        <button @click="clickDelete()">삭제</button>
                    </div>
                    
                </div>
            </template>
            <template v-else>
                <QNASecret 
                    :boardId="board.id"
                    :isUpdate="isUpdate"
                    :isDelete="isDelete"
                    v-on:goQNADetail="goQNADetail"
                    v-on:goQNAUpdateForm="goQNAUpdateForm"
                    v-on:deleteThisQNA="deleteThisQNA"
                />
            </template>
        </template>
    </div>
</template>

<script>
import { mapState } from "vuex";
import QNASecret from "@/components/qna/QNASecret.vue";
export default {
    name: "QNADetail",
    components: {
        QNASecret,
    },
    props: {
        boardId: {
            type: String,
            default: "",
        }
    },
    created() {
        // if (this.$route.params.pass == true) {
        //     this.setBoardData(this.boardId);
        // } else {
            // this.$router.push({ path: '/qna/detail/'+this.boardId+'/secret' });
        // }
        
        this.setBoardData(this.boardId);
    }, 
    data () {
        return {
            board: {
                id: "",
                title: "",
                author: "",
                phone: "",
                pwd: "",
                secret: "",
                date: "",
                hit: "",
                content: "",
            },
            isLoading: true,
            isPass: true,
            isUpdate: false,
            isDelete: false,
        }
    },
    methods: {
        setBoardData(boardId) {
            this.$store.dispatch('requestGetBoardDetail', { boardId: boardId })
            .then((response) => {
                this.isLoading = false;
                let data = response.data;

                if (data.boardSecret!=true || this.isLogin==true) {
                    this.isPass = true;
                } else {
                    this.isPass = false;
                }

                this.board.id = data.boardId;
                this.board.title = data.boardTitle;
                this.board.author = data.boardAuthor;
                this.board.pwd = data.boardPwd;
                this.board.phone = data.boardPhone;
                this.board.secret = data.boardSecret;
                this.board.date = (data.boardDateTime).split('T')[0];
                this.board.hit = data.boardHit;
                this.board.content = data.boardContent;
            })
            .catch((error) => {
                console.log(error);
                alert("오류로 인해 게시글 데이터를 불러오지 못했습니다. 목록으로 돌아갑니다.");
                this.$router.push({ path: '/qna', query: {page: 1}})
            })
        },
        clickUpdate() {
            this.$router.push({
                name: 'QNAWrite',
                params: {board: this.board, isModify: true},
            })
        },
        clickDelete() {
            if (this.isLogin == true) { // 관리자일때
                let isDelete = confirm("게시글을 삭제하시겠습니까?");
                if (isDelete) this.deleteThisQNA();
            } else { // 일반 사용자일때
                this.isDelete=true;
                this.isPass=false;
            }
        },
        goQNADetail() {
            this.isPass=true;
        },
        deleteThisQNA() {
            this.$store.dispatch('requestDeleteBoard', { boardId: this.boardId })
            .then(() => {
                alert("게시글 삭제 완료. 목록으로 돌아갑니다.");
                this.$router.push({ path: '/qna', query: {page: 1}});
            })
            .catch((error) => {
                console.log(error);
                alert("오류로 인해 게시글 데이터를 불러오지 못했습니다. 목록으로 돌아갑니다.");
                this.$router.push({ path: '/qna', query: {page: 1}});
            })
            
        },
    },
    computed: {
        ...mapState([
            'isLogin',
        ]),
        content() {
            return this.board.content.replace(/(\n|\r\n)/g, '<br>').replaceAll(" ", '&nbsp;');
        }
    },
}
</script>

<style scoped>

.qna-detail-content {
    width: 100%;
}

table {
    width: 100%;
    border: 2px solid #1f1f20;
    border-collapse: collapse;
}
td{
  width: 350px;
  padding: 10px;
  vertical-align: top;
  border: 1px solid rgb(47, 46, 48);
}

.left {
  font-size: 12px;
  width: 150px;
  padding: 10px;
  font-weight: bold;
  vertical-align: top;
  background: #efefef;
}

.right {
    font-size: 12px;
}

.qna-detail-content {
    margin: 20px;
}

.qna-detail-footer {
    text-align: right;
    margin-top: 20px;
}
.content{
    padding: 20px;
    font-size: 13px;
}
.main{
  font-weight: bold;
  color: rgb(0, 0, 0);
  border-bottom: 2px solid rgb(35, 35, 36);
}


</style>