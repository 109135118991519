import axios from 'axios'
import { setInterceptors } from './interceptors';

const BASE_URL = process.env.VUE_APP_API_URL;
const DEFAULT_CONTENT_TYPE = 'application/json';
const ASTAR = '*';
const ACCESS_METHODS = 'GET,PUT,POST,PATCH,DELETE,OPTIONS';

function createInstance() {
	const instance = axios.create({
        baseURL: BASE_URL,
        headers: {
            'Content-Type': DEFAULT_CONTENT_TYPE,
            'Access-Control-Allow-Origin': ASTAR,
            'Access-Control-Allow-Headers': ASTAR,
            'Access-Control-Allow-Methods': ACCESS_METHODS,
        }
    });
	return instance;
}
export const instance = createInstance();

function createInstanceWithAuth() {
	const instance = axios.create({
        baseURL: BASE_URL,
        headers: {
            'Content-Type': DEFAULT_CONTENT_TYPE,
            'Access-Control-Allow-Origin': ASTAR,
            'Access-Control-Allow-Headers': ASTAR,
            'Access-Control-Allow-Methods': ACCESS_METHODS,
            'Authorization': "",
        }
    });
	return setInterceptors(instance);
}
export const instanceWithAuth = createInstanceWithAuth();