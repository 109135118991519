<template>
    <div class="header-component login">
        <template v-if="isLogin">
            <ul class="nav">
                <li><strong>{{ memberId }}</strong></li>
                <li class="logout" @click="clickLogout()">로그아웃</li>
                <li><router-link to="/join" class="login">회원가입</router-link></li>
            </ul>
        </template>
        <template v-else>
            <ul class="nav">
                <li><router-link to="/login" class="login">로그인</router-link></li>
            </ul>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
    created() {
        this.setMemberData();
    },
    methods: {
        ...mapActions(["logout"]),
        clickLogout() {
            this.logout();
        },
        setMemberData() {
            // 로컬스토리지에 토큰이 있다면 새로고침 할때마다 유효한지 검사후 memberId 받아옴, 없으면 실행 안함
            let lsValue = localStorage.getItem('vuex')
            let token = ''
            if (lsValue) {
                token = JSON.parse(localStorage.getItem('vuex')).token;
            }

            if (token != null && token != '') {
                // 토큰 값이 있다면 로그인 유지
                this.$store.dispatch('requestGetMemberId')
                .then((response) => {
                    this.$store.dispatch('setMemberId', {memberId: response.data});
                })
                .catch((error) => { // 토큰값이 만료됐거나 올바르지 않은 토큰이면 로그아웃처리
                    console.log(error);
                })
            }

            
        },
    },
    computed: {
        ...mapState([
            'memberId', 'isLogin',
        ])
    },
}
</script>

<style scoped>
ul {
    text-align: right;
    margin: 0;
    height: 15px;
    margin-right: 10px;
    margin-top: 10px;
}

li {
    list-style: none;
    display: inline-block;
    padding: 0 5px;
}
.login {
    text-decoration: none;
    color: rgb(138, 138, 138);
}

.logout { 
    cursor: pointer;
}

.join {
    cursor: pointer;
}

</style>