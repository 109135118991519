import store from '@/store/index';
import router from '@/router/index';

export function setInterceptors(instance) {
	instance.interceptors.request.use(
		function(config) {
			// Do something before request is sent
			config.headers.Authorization = 'Bearer ' + store.state.token;
			return config;
		},
		function(error) {
			// Do something with request error
			return Promise.reject(error);
		},
	);

	// Add a response interceptor
	instance.interceptors.response.use(
		function(response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			return response;
		},
		function(error) {
			// Any status codes that falls outside the range of 2xx cause this function to trigger
			// Do something with response error
            if (error.response.status == 401) {
                alert("토큰이 만료되었습니다. 다시 로그인해주세요.");
                store.dispatch('logout');
				router.push({path: '/login'});
            }
			return Promise.reject(error);
		},
	);
	return instance;
}