<template>
    <div class="product-wrap">
        <div class="title">&nbsp;&nbsp;&nbsp;<b>상품목록</b></div><hr/>
        <template v-if="isPass">
            <router-link to='/productwrite' class="goWrite"><strong>상품 등록</strong></router-link>
        </template>
        <div class="product" v-for="product in productList" :key="product.id">
            <img :src="product.productImage" alt="상품사진" width="200px" height="200px" style="margin-bottom:10px" @click="clickProductTitle(product.productId)" class="btn-3" >
            <div class="btn10" @click="clickProduct(product.id)" style="margin-bottom:5px" >
                <div class="article-name-box" @click="clickProductTitle(product.productId)">
                    <strong>{{ product.productName }}</strong>
                </div>
            </div>
            <p class="pd">{{product.productDescription}}</p>
        </div>
        <div class="pageListBox">
            <ul class="pageList">
                <li v-if=isBeforeButton @click="clickBeforeButton()" class="BNbutton">이전</li>
                <li v-for="p in pages" :key="p">
                    <template v-if="p==page">
                        <strong>{{p}}</strong>
                    </template>
                    <template v-else>
                        <router-link 
                            :to ="{path: '/product', query: {page: (p)}}" 
                            class="page_link"
                        >
                            {{p}}
                        </router-link>
                    </template>
                </li>
                <li v-if=isNextButton @click="clickNextButton()" class="BNbutton">다음</li>
            </ul>
        </div>
    </div>
     
    
</template>


<script>
import { mapState } from "vuex";
export default {
    name: "Product",
    components: {

    },
    created() {
        this.page = this.$route.query.page;
        this.setProductData(this.page);
    },
    data () {
        return {
            productList: [],
            totalPages: '',
            pages: [],//페이지가 10개를 넘어갈 경우 1~10, 11~20씩 나눌 덩어리
            page: '',
            isNextButton: '',
            isBeforeButton: '',
            // isPass: false,
        }
    },
    methods: {
        setProductData(page) {
            // if (this.isLogin) {
            //     this.isPass=true;
            // }
            this.$store.dispatch('requestGetProduct', {page: page})
            .then((response) => {
                let productList = response.data.productDtoList;
                let totalPages = response.data.totalPages;
                this.totalPages = totalPages;
                let pages = parseInt((parseInt(page) + 9) / 10); // 12 -> pages: 2, 35 -> pages: 4 
                if (pages == 1) {
                    //pages == 1이라는건 1~10페이지중 하나라는 뜻 이므로 이전 버튼 비활성화
                    this.isBeforeButton = false;
                } else {
                    this.isBeforeButton = true;
                }
                this.isNextButton = true;
                for (let i = pages*10-9; i <= pages*10; i++) {
                    if (i > totalPages) {
                        this.isNextButton = false;
                        continue;
                    }
                    this.pages.push(i);
                }
                for (let i = 0; i < productList.length; i++) {
                    const product = productList[i];
                    this.productList.push(
                        {
                            productId: product.productId,
                            productName: product.productName,
                            productDescription: product.productDescription,
                            productImage: product.productImage,

                        }
                    )
                }
            })
            .catch((error) => {
                console.log(error)
                alert("오류로 인해 게시판 데이터를 불러오지 못했습니다. 메인페이지로 돌아갑니다.");
                this.$router.push({ path: '/' })
            })

            
            
        },
        clickProductTitle(product_id) {
            this.$router.push({ path: '/product/detail/'+product_id })
        },
        clickBeforeButton() {
            let pageMock10 = parseInt((parseInt(this.page) + 9) / 10); // 현재 페이지+9 를 10으로 나눈 몫
            this.$router.push({ path: '/product', query: { page: ((pageMock10-2)*10)+10 }})
        },
        clickNextButton() {
            let pageMock10 = parseInt((parseInt(this.page) + 9) / 10);
            this.$router.push({ path: '/product', query: { page: (pageMock10*10)+1 }})
        }
    },
    computed: {
        ...mapState([
            'isLogin',
        ]),
        isPass () {
            return this.isLogin;
        }
    }
}
</script>

<style scoped>
.pd{
    font-family: "proxima-nova", sans-serif;
    font-weight: 500;
    font-size: 13px;
    text-transform: uppercase!important;
    letter-spacing: 2px;
    cursor: hand;
    text-align: center;
    text-transform: capitalize;
    border-radius:50px;
    position: relative;
    overflow: hidden!important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background: transparent!important;
    z-index:10;
    
}


.product {
    display: inline-block;
    width: 200px;
    height: 350px;
    margin-top: 30px;
    padding: 1px;
    
}

a {
    text-decoration: none;
    color: black;
}

.goWrite {
    text-decoration: none;
    margin-bottom: 20px;
    padding: 0px 10px;
    border-radius: 5px;
    float: right;
}
.page {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.pageListBox {
    text-align: center;
}

ul {
    margin: 0;
}

li {
    list-style: none;
    display: inline-block;
    margin: 13px 0;
    padding: 0 15px;
    
}

.page_link {
    color: black;
    text-decoration: none;
}

table {
    
}
.btn10{
    font-family: "proxima-nova", sans-serif;
    font-weight: 500;
    font-size: 20px;
    text-transform: uppercase!important;
    letter-spacing: 2px;
    cursor: hand;
    text-align: center;
    text-transform: capitalize;
    border-radius:50px;
    position: relative;
    overflow: hidden!important;
    -webkit-transition: all .3s ease-in-out;
    -moz-transition: all .3s ease-in-out;
    -o-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background: transparent!important;
    z-index:10;
    
}

.btn10:hover{
    color:rgb(82, 70, 70);
    cursor: pointer;
}

  
 
   .btn-3:hover  { 
        cursor: pointer;
       opacity:0.8;
       text-align:center; 
       color:#000000;
        }

.article-name-box {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
}

p.pd {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
}

</style>