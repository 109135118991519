<template>
    <div class="productWrite">
        <div class="title">&nbsp;&nbsp;&nbsp;<b>상품추가</b><hr/></div>
            <div class="productTable">
                <table class="pdtb">
                    <tr>
                        <td width="200px">상품이름</td>
                        <td>
                            <ValidationProvider ref="productName" rules="max:20">
                            <input type="text" v-model.trim="productData.productName" class="title-input" name="상품 이름">
                            </ValidationProvider>
                        </td>
                    </tr>
                    <tr>
                        <td>상품사이즈</td>
                        <td>
                            <ValidationProvider ref="productDescription" rules="max:20">
                            <input type="text" v-model.trim="productData.productDescription" name="상품 사이즈">
                            </ValidationProvider>
                        </td>
                    </tr>
                    <tr>
                        <td>상품 대표 이미지</td>
                        <td>
                            <input @change="onInputImage()" type="file" ref="imageFile" accept=".jpg, .jpeg, .gif, .png">
                        </td>
                    </tr>
                    <tr>
                        <td>상품 상세 이미지</td>
                        <td>
                            <input @change="onInputImage2()" type="file" ref="imageFile2" accept=".jpg, .jpeg, .gif, .png">
                        </td>
                    </tr>
                    <template v-if="isModify">
                        <tr>
                            <td>기존 상품 대표 이미지</td>
                            <td>
                                <img :src="oldProductImage" alt="상품대표사진" width="300px" height="300px"/>
                            </td>
                        </tr>
                        <tr>
                            <td>기존 상품 상세 이미지</td>
                            <td>
                                <img :src="oldProductDetailImage" alt="상품상세사진" width="100%" class="content_image"/>
                            </td>
                        </tr>
                    </template>
                </table>
                <div class="footer">
                    <template v-if="isModify">
                        <button class="btn1"><router-link :to ="{path: '/product/detail/'+product.productId}" class="btn-1">이전</router-link></button>
                        <button @click="clickModify()">수정</button>
                    </template>
                    <template v-if="!isModify">
                        <button class="btn1"><router-link :to ="{path: '/product', query: {page: 1}}" class="btn-1">이전</router-link></button>
                        <button @click="clickSave()">등록</button>
                    </template>
                </div>
          </div>
    </div>
</template>


<script>
export default {
    props: {
        product: {
            type: Object,
        },
        isModify: {
            type: Boolean,
            default: false,
        }
    },
    created() {
        if (this.isModify) {
            this.setProductData();
        }
    },
    data() {
        return  {
            productData: {
                productId: '',
                productName: '',
                productDescription: '',
            },
            productImage: '',
            productDetailImage: '',
            oldProductImage: '',
            oldProductDetailImage: '',
        }
    },
    methods: {
        setProductData() {
            let data = this.product;
            this.productData.productId = data.productId;
            this.productData.productName = data.productName;
            this.productData.productDescription = data.productDescription;
            this.oldProductImage = data.productImage;
            this.oldProductDetailImage = data.productDetailImage;
        },
        async clickSave() {
            /* 전체 폼 유효성 검사 */
            if (this.productData.productName == '' || 
                this.productData.productDescription == '') {
                alert('빈 항목을 채워주세요');
                return false;
            }

            let imgFile1 = this.$refs.imageFile.files[0];
            let imgFile2 = this.$refs.imageFile2.files[0];
            if(imgFile1 == undefined || imgFile2 == undefined) {
                alert("상품 사진은 필수입니다.");
                return;
            }

            const refProductName = await this.$refs.productName.validate();
            const refProductDescription = await this.$refs.productDescription.validate();

            if (!refProductName.valid) {
                alert(refProductName.errors[0]);
                return false;
            } else if (!refProductDescription.valid) {
                alert(refProductDescription.errors[0]);
                return false;
            }

            /* 유효성 검사 완료 후 게시글 등록 */

            let formData = new FormData();
            let productData = this.productData;
            formData.append('imageFile', this.productImage);
            formData.append('imageFile2', this.productDetailImage);
            formData.append('productDto', new Blob([JSON.stringify(productData)] , {type: "application/json"}))
            this.$store.dispatch('requestInsertProduct', formData)
            .then(() => {
                alert("상품 등록 완료");
                this.$router.push({ path: '/product', query: {page: 1} })
            })
            .catch((error) => {
                console.log(error)
            })
        },
        async clickModify() {
            /* 전체 폼 유효성 검사 */
            if (this.productData.productName == '' || 
                this.productData.productDescription == '') {
                alert('빈 항목을 채워주세요');
                return false;
            }

            const refProductName = await this.$refs.productName.validate();
            const refProductDescription = await this.$refs.productDescription.validate();

            if (!refProductName.valid) {
                alert(refProductName.errors[0]);
                return false;
            } else if (!refProductDescription.valid) {
                alert(refProductDescription.errors[0]);
                return false;
            }

            let formData = new FormData();
            let productData = this.productData;
            formData.append('imageFile', this.productImage);
            formData.append('oldImageFile', new Blob([JSON.stringify(this.oldProductImage)] , {type: "application/json"}))
            formData.append('imageFile2', this.productDetailImage);
            formData.append('oldImageFile2', new Blob([JSON.stringify(this.oldProductDetailImage)] , {type: "application/json"}))
            formData.append('productDto', new Blob([JSON.stringify(productData)] , {type: "application/json"}))
            this.$store.dispatch('requestUpdateProduct', formData)
            .then(() => {
                alert("상품 수정 완료");
                this.$router.push({path: '/product/detail/'+this.product.productId})
            })
            .catch((error) => {
                console.log(error)
            })
        },
        onInputImage() {
            /* 유효성 검사 내용 -> 파일 크기 제한 : 30mb, 확장자 : jpg, jpeg, png, gif */
            let imgFileName = this.$refs.imageFile.files[0].name;
            let fileForm = /(.*?)\.(jpg|jpeg|png|gif)$/;
            let maxSize = 30 * 1024 * 1024;
            let fileSize = this.$refs.imageFile.files[0].size;

            if(imgFileName != "" && imgFileName != null && imgFileName != undefined) {
                if(!(imgFileName.match(fileForm))) {
                    alert("이미지 파일만 업로드 가능합니다.");
                    this.$refs.imageFile.value="";
                    return;
                } else if(fileSize > maxSize) {
                    alert("파일 사이즈는 30MB까지 가능합니다.");
                    this.$refs.imageFile.value="";
                    return;
                }
            }
            /* 통과되면 파일 넣음 */
            this.productImage = this.$refs.imageFile.files[0];

        },
        onInputImage2(){
            let imgFileName = this.$refs.imageFile2.files[0].name;
            let fileForm = /(.*?)\.(jpg|jpeg|png|gif)$/;
            let maxSize = 30 * 1024 * 1024;
            let fileSize = this.$refs.imageFile2.files[0].size;

            if(imgFileName != "" && imgFileName != null && imgFileName != undefined) {
                if(!(imgFileName.match(fileForm))) {
                    alert("이미지 파일만 업로드 가능합니다.");
                    this.$refs.imageFile2.value="";
                    return;
                } else if(fileSize > maxSize) {
                    alert("파일 사이즈는 30MB까지 가능합니다.");
                    this.$refs.imageFile2.value="";
                    return;
                }
            }
            this.productDetailImage = this.$refs.imageFile2.files[0];
        },
        goProductDetail() {
            this.$router.push({ path: '/product/detail/'+ this.productData.productId });
        },
    }
}
</script>

<style scoped>
.productWrite {
  margin-top: 20px;
} 
.inputContainer {
  display: flex;
  flex-direction: column;
}
label {
  margin: 10px 0;
  font-weight: 700;
}
input,
textarea {
  height: 40px;
  margin-bottom: 20px;
  padding: 0 10px;

}
textarea {
  margin-bottom: 10px;
  padding: 5px 10px;
}
.btnBox {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 50px;
}
.resetBtn {
  margin-left: 20px;
  text-decoration-line:none;
  color:black;
}
.btn {
  padding: 2px 0;
  font-weight: 600;
  border-radius: 5px;

}

.btn:hover {
  cursor: pointer;
  color: blue;
}
.btn-1 {
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.footer {
    text-align: center;
}
</style>
