import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue';
import About from '../views/About.vue';
import Map from '../views/Map.vue';
import Manual from '../views/Manual';
import Patent from '../views/Patent.vue';
import Product from '../views/Product.vue';
import ProductDetail from '../views/ProductDetail.vue';
import Privacy from '../views/Privacy.vue';
import QNA from '../views/QNA.vue';
import QNADetail from '../views/QNADetail.vue';
import QNAWrite from '../views/QNAWrite.vue';
import Login from '../views/Login.vue';
import Join from '../views/Join.vue';
import ProductWrite from '../views/ProductWrite.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Main,
  },
  {
    path: '/about',
    component: About,
  },
  {
    path: '/map',
    component: Map,
  },
  {
    path: '/patent',
    component: Patent,
  },
  {
    path: '/product',
    component: Product,
  },
  {
    path: '/product/detail/:product_id',
    component: ProductDetail,
    props: true,
  },
  {
    path: '/manual',
    component: Manual,
  },
  {
    path: '/privacy',
    component: Privacy,
  },
  {
    path: '/qna',
    component: QNA,
  },
  {
    path: '/qna/detail/:boardId',
    component: QNADetail,
    props: true,
  },
  {
    path: '/qna/write',
    component: QNAWrite,
    name: 'QNAWrite',
    props: true,
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/join',
    component: Join,
  },
  {
    path: '/productwrite',
    component: ProductWrite,
    name: 'ProductWrite',
    props: true,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router;
