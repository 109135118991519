<template>
  <div class="patent">
    <PatentDetail />
  </div>
</template>

<script>
import PatentDetail from '../components/patent/PatentDetail.vue';
export default {
  components: { PatentDetail },
};
</script>
<style scoped>
    .patent{
        display: inline;
    }
  
</style>