<template>
  <div id="app">
    <MainHeader></MainHeader>
    <div class="center-wrap">
      <MainSideBar></MainSideBar>
      <div class="content-wrap">
        <router-view :key="$route.fullPath"></router-view>
      </div>
    </div>
    <MainFooter></MainFooter>
  </div>
</template>

<script>
import MainHeader from "./components/MainHeader.vue";
import MainFooter from "./components/MainFooter.vue";
import MainSideBar from "./components/MainSideBar.vue";

export default {
  name: "Main",
  components: {
    MainHeader,
    MainSideBar,
    MainFooter
  },
}
</script>

<style>
body {
  margin: 0;
}

#app {
  min-width: 1100px;
}

.center-wrap {
  /* min-height: calc(100vh-450px); */
  min-height: calc(100vh - 449px);
  width: 1100px;
  margin: 0 auto;
}

.content-wrap {
  display: inline-block;
  width: 883px;
  padding: 20px;
}

/* 컨텐츠 타이틀 공통 적용*/
.title {
  font-size: 14px;
}
</style>
