<template>
    <div class="qnaContainer">
        <div class="title">&nbsp;&nbsp;&nbsp;<b>상품문의</b><hr/></div>
        <template v-if="isLoading">
            <!-- <q-spinner-cube color="primary" size="5em"/> -->
            로딩중 ...
        </template>
        <template v-else>
            <div class="qnaTable">
                <table width="100%">
                    <thead class="table-header">
                        <tr class="qnatitle" height="50px">
                            <th class="articleNo">글번호</th>
                            <th class="articleName">제목</th>
                            <th class="author">글쓴이</th>
                            <th class="date">날짜</th>
                            <th class="hit">조회수</th>
                        </tr>
                    </thead>
                    <tbody class="tbody" id="my-table">
                        <tr height="30px" v-for="board in boardList" :key="board.id">
                            <td style="text-align: center"> {{ board.id }} </td>
                            <td> 
                                <div class="article-name-box" @click="clickBoardTitle(board.id)">
                                    {{ board.title }} 
                                </div>
                            </td>
                            <td style="text-align: center"> {{ board.author }} </td>
                            <td style="text-align: center"> {{ board.dateTime }} </td>
                            <td style="text-align: center"> {{ board.hit }} </td>
                        </tr>
                    </tbody>
                </table>
                <div class="pageListBox">
                    <ul class="pageList">
                        <li v-if=isBeforeButton @click="clickBeforeButton()" class="BNbutton">이전</li>
                        <li v-for="p in pages" :key="p">
                            <template v-if="p==page">
                                <strong>{{p}}</strong>
                            </template>
                            <template v-else>
                                <router-link 
                                    :to ="{path: '/qna', query: {page: (p)}}" 
                                    class="page_link"
                                >
                                    {{p}}
                                </router-link>
                            </template>
                        </li>
                        <li v-if=isNextButton @click="clickNextButton()" class="BNbutton">다음</li>
                    </ul>
                </div>
            <router-link :to="{ path: '/qna/write' }" class="goWrite"><strong>글작성</strong></router-link>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: "QNA",
    components: {

    },
    created() {
        this.page = this.$route.query.page;
        this.setBoardData(this.page);
    },
    data () {
        return {
            boardList: [],
            totalPages: '',
            pages: [],//페이지가 10개를 넘어갈 경우 1~10, 11~20씩 나눌 덩어리
            page: '',
            isNextButton: '',
            isBeforeButton: '',
            isLoading: true,
        }
    },
    methods: {
        setBoardData(page) {
            this.$store.dispatch('requestGetBoard', {page: page})
            .then((response) => {
                this.isLoading = false;
                let boardList = response.data.boardDtoList;
                let totalPages = response.data.totalPages;
                this.totalPages = totalPages;
                let pages = parseInt((parseInt(page) + 9) / 10); // 12 -> pages: 2, 35 -> pages: 4 
                if (pages == 1) {
                    //pages == 1이라는건 1~10페이지중 하나라는 뜻 이므로 이전 버튼 비활성화
                    this.isBeforeButton = false;
                } else {
                    this.isBeforeButton = true;
                }
                this.isNextButton = true;
                for (let i = pages*10-9; i <= pages*10; i++) {
                    if (i > totalPages) {
                        this.isNextButton = false;
                        continue;
                    }
                    this.pages.push(i);
                }
                for (let i = 0; i < boardList.length; i++) {
                    const board = boardList[i];
                    if (board.boardSecret) {
                        board.boardTitle += '🔒︎'
                    }
                    this.boardList.push(
                        {
                            id: board.boardId,
                            author: board.boardAuthor,
                            title: board.boardTitle,
                            dateTime: (board.boardDateTime).split('T')[0],
                            secret: board.boardSecret,
                            hit: board.boardHit,
                        }
                    )
                }
            })
            .catch((error) => {
                console.log(error)
                alert("오류로 인해 게시판 데이터를 불러오지 못했습니다. 메인페이지로 돌아갑니다.");
                this.$router.push({ path: '/' })
            })

            
        },
        clickBoardTitle(boardId) {
            this.$router.push({ path: '/qna/detail/'+boardId })
            
        },
        clickBeforeButton() {
            let pageMock10 = parseInt((parseInt(this.page) + 9) / 10); // 현재 페이지+9 를 10으로 나눈 몫
            this.$router.push({ path: '/qna', query: { page: ((pageMock10-2)*10)+10 }})
        },
        clickNextButton() {
            let pageMock10 = parseInt((parseInt(this.page) + 9) / 10);
            this.$router.push({ path: '/qna', query: { page: (pageMock10*10)+1 }})
        }
    }
}
</script>

<style scoped>
/* 페이지 관련 css*/

.pageListBox {
    text-align: center;
}

ul {
    margin: 0;
}

li {
    list-style: none;
    display: inline-block;
    margin: 13px 0;
    padding: 0 5px;
    
}

.page_link {
    color: black;
    text-decoration: none;
}

table {
    
}

.qnaTitle {
    color: var(--mild-color);
    display: flex;
    justify-content: center;
    align-items: center;
}

.fa-edit {
    padding-right: 20px;
}

.goWrite {
    text-decoration: none;
    float: right;
    font-size: 15px;
    margin-bottom: 20px;
    border: 1px solid var(--mild-color);
    color: var(--mild-color);
    padding: 0px 10px;
    border-radius: 5px;
}

.goWrite:hover {
    background-color: var(--mild-color);
    color: lightslategray;
}

.qnaTable {
    margin: 20px;
}

.table-header {
    width: 100%;
    font-size: 13px;
    text-align: center;
    border-collapse: collapse;
}

.articleNo {
    width:12%;
}

.articleName {
    width: 45%;
}

.article-name-box {
    display: inline;
}

.article-name-box:hover {
    cursor: pointer;
}

.author {
    width: 20%;
}

.date {
    width: 13%;
}

.hit {
    width: 10%;
}

td {
    border-bottom: 1px solid #ddd;
}

.page {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.BNbutton:hover{
    cursor: pointer;
}
.qnatitle{
  font-size: 12px;
  font-weight: bold;
  background: #efefef;
}
</style>