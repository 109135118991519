<template>
    <div class="header-component nav-bar">
        <ul class="nav-bar-ul">
            <li><router-link to ="/about" class="btn-1">회사소개</router-link></li>
            <li><router-link to ="/map" class="btn-1">회사위치</router-link></li>
            <li><router-link :to ="{path: '/product', query: {page: 1}}" class="btn-1">상품목록</router-link></li>
            <li><router-link :to ="{path: '/qna', query: {page: 1}}" class="btn-1">상품문의</router-link></li>
            <li><router-link to ="/patent" class="btn-1">특허인증</router-link></li>
        </ul>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
ul {
    height: 100%;
    margin: 0 auto;
    padding: 0;
    text-align: center;
}

li {
    list-style: none;
    display: inline-block;
    margin: 13px 0;
    padding: 0 50px;
    color: white;

}
.footer_link {
    color: #fff;
    text-decoration: none;
}
.btn-1 {
	letter-spacing: 0;
	-webkit-transition: all .28s ease-in-out;
	transition: all .28s ease-in-out;
    text-decoration: none;
    color: #fff;
}
.btn-1:hover,
.btn-1:focus,
.btn-1:active {
	letter-spacing: 5px;
}
.btn-1:after,
.btn-1:before {
	border: 1px solid rgba(255, 255, 255, 0);
	bottom: 0;
	content: " ";
	display: block;
	margin: 0 auto;
	position: relative;
	-webkit-transition: all .28s ease-in-out;
	transition: all .28s ease-in-out;
	width: 0;
}


.btn-1:hover:before {
	bottom: auto;
	top: 0;
}

</style>