<template>
  <div class="qna-secret-container">
    <table>
      <tr>
        <div class="description">비밀글 입니다. 비밀번호를 입력하세요.</div>
      </tr>
      <tr class="password-input-form">
        <input type="password" v-model="pwd"> <button @click="clickConfirm()">확인</button> 
        <template v-if="isDelete || isModify"><button @click="clickBack()">돌아가기</button></template>
      </tr>
      <tr>
        
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'QNASecret',
  props: {
        boardId: {
          type: String,
          default: "",
        },
        isModify: {
          type: Boolean,
          default: false,
        },
        isDelete: {
          type: Boolean,
          deafult: false,
        }
    },
  created() {
  },
  data() {
    return {
      pwd: '',
    }
  },
  methods: {
    clickConfirm() {
      this.$store.dispatch('requestCheckPassword', {boardId: this.boardId, pwd: this.pwd})
      .then((response) => {
        let isPass = response.data;
        if (isPass == true) {
          if (this.isModify && !this.isDelete) {
            this.$emit("goQNAUpdateForm")
          } else if (this.isDelete && !this.isModify) {
            let isDelete = confirm("게시글을 삭제하시겠습니까?");
            if (isDelete) this.$emit("deleteThisQNA");
          } else {
            this.$emit("goQNADetail");
          }
        } else {
          alert("비밀번호가 올바르지 않습니다.");
        }
      })
      .catch((error) => {
        console.log(error);
      })
    },
    clickBack() {
      this.$emit("goQNADetail");
    },
  }
}
</script>

<style scoped>
.qna-secret-container {
  height: 500px;
  /* margin: 0 auto; */
}

.password-input-form {
  text-align: center;
}


table {
  margin: 150px auto;
}

</style>