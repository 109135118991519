export default{

    setLoginToken(state, payload) {
        state.isLogin = true;
        state.token = payload.token;
        state.memberId = payload.memberId;
    },

    logout(state) {
        state.isLogin = false;
        state.token = '';
        state.memberId = '';
    },

    setMemberId(state, payload) {
        state.memberId = payload.memberId;
    }

}