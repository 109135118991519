<template>
  <div class="map">
    <MapDetail />
  </div>
</template>

<script>
import MapDetail from '../components/map/MapDetail.vue';
export default {
  components: { MapDetail },
};
</script>
<style scoped>
    .map{
        display: inline-block;
    }
  
</style>