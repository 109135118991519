<template>
  <div>
    <div class="title">&nbsp;&nbsp;&nbsp;<b>로그인</b><hr/></div>
    <div class="loginFormBox">
        <form class="loginForm">
            <div class="inputBox">
                <div class="description">관리자만 로그인할 수 있습니다.</div>
                <input type="text" id="ID" v-model.trim="memberId" placeholder="아이디" />
                <input type="password" id="Password" v-model.trim="memberPwd" placeholder="비밀번호" />
            </div>
        </form>
        <div class="btnBox">
            <button type="button" @click="clickLogin()" class="loginBtn">로그인</button>
        </div>
    </div>
  </div>
</template>

<script>

export default {
    name: "Login",
    data() {
        return {
            memberId: '',
            memberPwd: '',
        }
    },
    methods: {
        clickLogin() {
            let memberId = this.memberId;
            let memberPwd = this.memberPwd;
            if (memberId == '' || memberPwd == '') {
                alert('빈 항목을 채워주세요');
                return ;
            }
            this.$store.dispatch('requestLogin', {memberId: this.memberId, memberPwd: this.memberPwd})
            .then((response) => {
                    this.$store.dispatch('setLoginToken', {token: response.data, memberId: this.memberId});
                    this.$router.push({ path: '/'});
            })
            .catch(() =>{
                alert("아이디 또는 비밀번호를 확인해주세요.");
            })
        },
    }
}
</script>

<style scoped>
.loginContainer {
    padding: 0 500px;
}

h1 {
    margin: 50px 0 20px 0;
    font-size: 50px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fa-user-check {
    font-size: 40px;
    margin-left: 15px;
}

.loginFormBox {
    margin-top: 100px;
}

.loginForm {

    display: flex;
    flex-direction: column;
    align-items: center;
}

.inputBox {
    display: flex;
    flex-direction: column;
    width: 400px;
    font-size: 20px;
}

input {
    height: 50px;
    margin-bottom: 20px;
    border-radius: 2px;
    font-size: 20px;
}

.btnBox {
    display: flex;
    justify-content: center;
    margin: 10px 0 50px 0;
}

button {
    background-color: #203583;
    color: white;
    border-radius: 2px;
    height: 50px;
    width: 400px;
    font-weight: 900;
    border-style: none;
}

button:hover {
    background-color: #132358;
    cursor: pointer;
}

.description {
    margin-bottom: 30px;
    text-align: center;
}
</style>
